import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  styled,
} from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import globalStyles from 'src/styles/globalStyles'

import {
  Container as MuiContainer,
} from '@material-ui/core'

import Layout from 'src/components/layout'
import { SectionHeader } from 'src/components/headers'

const Container = styled(MuiContainer)(spacing)

const Privacy = ({ data, pageContext }) => {
  const Privacy = data.contentfulPrivacy

  return (
    <Layout pageContext={pageContext}>
      <Container pt={8} pb={8}>
        <SectionHeader>{Privacy.name}</SectionHeader>
        {Privacy.content ? (
          documentToReactComponents(JSON.parse(Privacy.content.raw))
        ) : ''}
      </Container>
    </Layout>
  )
}

export default Privacy

export const pageQuery = graphql`
  query Privacy($id: String!) {
    contentfulPrivacy(id: { eq: $id }) {
      name
      content {
        raw
      }
    }
  }
`
